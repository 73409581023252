<template>
  <el-pagination
    background
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page.sync="currentPage"
    :page-size="pageSize"
    :page-sizes="[10, 20, 30, 40]"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pageTotal"
  ></el-pagination>
</template>

<script>
export default {
  props: {
    pageTotal: {
      type: Number,
      default: 0
    }
  },

  watch: {
    pageTotal: {
      handler (newV, oldV) {
        this.pageTotal = newV
      }
    }
  },
  data () {
    return {
      pageSize: 10,
      currentPage: 1
    }
  },

  methods: {
    reset () {
      this.pageSize = 10
      this.currentPage = 1
    },

    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.$emit('sendInfo', {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.$emit('sendInfo', {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      })
    }
  }
}
</script>

<style></style>
