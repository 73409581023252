<template>
  <div>
    <div class="formContent">
      <div class="left">
        <el-form
          label-width="120px"
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          class="dialog-form add-enclousre-form"
        >
          <el-form-item v-if="false" label=" 区域编号">
            <el-input v-model="form.id" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label=" 区域名称" prop="name">
            <el-input v-model.trim="form.name"></el-input>
          </el-form-item>
          <el-form-item label="区域类型" prop="type">
            <el-select v-model.trim="form.type" placeholder="请选择" @change="changeAlarmType">
              <el-option
                v-for="item in typeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属企业" :rules="[{ required: true, message: '不能为空' }]">
            <companySelect
              ref="companySelect"
              :clearable="true"
              :companyTree="companyList"
              :valueName="valueNameAdd"
              :value="valueAdd"
              @getValue="getAddGroupId"
            ></companySelect>
          </el-form-item>

          <el-form-item label="报警类型" prop="alarmType">
            <el-select v-model="form.alarmType" placeholder="请选择" :disabled="typeDisabled">
              <el-option
                v-for="item in alarmTypeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域颜色">
            <el-select v-model="form.color" placeholder="请选择" @change="changeColorType">
              <el-option
                v-for="item in colorList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警车辆数阈值" prop="threshold" v-if="form.type !== '1'">
            <el-row>
              <el-col :span="14">
                <el-input v-model.number.trim="form.threshold" size="small"></el-input>
              </el-col>
              <el-col :span="4">辆</el-col>
            </el-row>
          </el-form-item>

          <el-form-item label="报警提示内容" prop="content" v-if="form.type === '1'">
            <el-input v-model.trim="form.content" type="textarea" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="预警提前量" prop="threshold" v-if="form.type === '1'">
            <el-row>
              <el-col :span="14">
                <el-input v-model.number.trim="form.threshold" placeholder="单位：米"></el-input>
              </el-col>
              <el-col :span="4">米</el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <div class="top">
          <!-- <el-radio-group v-model="form.areaType" @change="drawMap">
            <el-radio :label="1">圆形</el-radio>
            <el-radio :label="2">矩形</el-radio>
            <el-radio :label="3">多边形</el-radio>
          </el-radio-group>-->
          <el-button-group>
            <el-button
              type="primary"
              size="small"
              v-if="JSON.stringify(this.itemId) !== '{}' && this.itemId.id"
              @click="mapEdit"
            >点击编辑</el-button>
            <el-button type="primary" size="small" v-else @click="drawMap">点击绘制</el-button>
            <el-button type="primary" class="clearMap" size="small" @click="clear">点击清除</el-button>
          </el-button-group>
        </div>
        <div id="container" class="definedMap"></div>
      </div>
    </div>
    <div class="dialog-btn">
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="clsoeDia(true)">取消</el-button>
        <el-button type="primary" size="small" @click="onSave">保存</el-button>
      </span>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import { insertLocation, updateLocation } from '@/api/lib/gps-api'
import { getBusinessCompanyTree } from '@/api/lib/api.js'
import companySelect from '@/components/treeSelect/companySelect.vue'
import { compareAscSort } from '@/common/utils/index'
export default {
  components: {
    companySelect
  },
  props: {
    itemId: {
      type: Object
    },
    typeList: {
      type: Array
    },
    alarmTypeList: {
      type: Array
    },
    colorList: {
      type: Array
    },
  },
  data () {
    var checkRangeNumber = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (typeof value !== 'number' || value < 0 || value > 10000) {
          callback(new Error('请输入0-10000的整数'))
        } else {
          callback()
        }
      }
    }
    // 区域名称
    const checkAreaName = (rule, value, callback) => {
      if (value && value !== '') {
        let regEn = /^[\u4E00-\u9FA5a-zA-Z0-9]+$/
        if (!regEn.test(value)) {
          callback(new Error('请输入中文英文数字'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      chooseCar: [],
      polyEditor: null,
      rectangleEditor: null,
      circleEditor: null,
      mouseTool: null,
      polygon: null,
      rectangle: null,
      circle: null,
      color: '#66B1FF',
      valueNameAdd: null,
      valueAdd: null,
      companyList: [],
      map: null,
      form: {
        type: null,
        alarmType: null,
        name: '',
        threshold: '',
        area: null,
        areaType: 2,
        content: '',
        color: ''
      },
      type: 2,
      typeDisabled: false,
      editorColor: {
        1: {
          strokeColor: '#059370',
          fillColor: '#9ed09d'
        },
        2: {
          strokeColor: '#EA546D',
          fillColor: '#de7171'
        },
        3: {
          strokeColor: '#71076e',
          fillColor: '#b374b1'
        }
      },
      rules: {
        name: [
          { required: true, message: '请输入 区域名称', trigger: 'blur' },
          { validator: checkAreaName, trigger: 'change' }
        ],
        type: [{ required: true, message: '请输入区域类型', trigger: 'blur' }],
        alarmType: [
          { required: true, message: '请输入报警类型', trigger: 'blur' }
        ],
        threshold: [
          { required: true, message: '请输入阀值', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'change' }
        ],
        content: [
          { required: true, message: '请输入报警下发内容', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    /** 加载地图和相关组件 */
    loadmap () {
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        zoom: 11,
        resizeEnable: true,
        expandZoomRange: true,
        features: ['bg', 'road', 'building', 'point']
      })
      this.map = map
      // 编辑
      if (JSON.stringify(this.itemId) !== '{}' && this.itemId.id) {
        this.itemId.type = this.itemId.type + ''
        this.itemId.alarmType = this.itemId.alarmType + ''
        this.valueAdd = this.itemId.companyId
        this.valueNameAdd = this.itemId.companyName
        this.form = { ...this.itemId }
        this.typeDisabled = this.form.type === 0
        this.mapEdit()
      } else {
        this.map.setCenter([105.83, 32.43])
      }
    },
    /** 区域类型改变 */
    changeAlarmType (value) {
      if (value === 0) {
        this.form.alarmType = 2
        this.typeDisabled = true
      } else {
        this.form.alarmType = null
        this.typeDisabled = false
      }
    },
    /** 颜色类型改变 */
    changeColorType (value) {
      this.drawMap()
    },

    getAddGroupId (v) {
      this.form.companyId = v
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    // 新增修改位置
    onSave () {
      if (!this.form.area) {
        this.$message.warning('请绘制区域')
        return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = this.form
          if (this.itemId.id) {
            updateLocation(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            insertLocation(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('closedia', !flag)
    },

    /** 新增 ————画图 */
    drawMap () {
      let self = this
      let color = self.editorColor[self.form.color === '' ? 1 : self.form.color]
      self.clear()
      self.form.area = ''
      if (self.mouseTool) self.mouseTool.close()
      self.map.plugin(['AMap.MouseTool'], function () {
        self.mouseTool = new AMap.MouseTool(self.map)
        // 监听draw件可获取画好的覆盖物
        self.mouseTool.on('draw', function (e) {
          self.getPath(e.obj.getPath())
        })
        switch (self.form.areaType) {
          case 1:
            /** 画圆 */
            self.circle = self.mouseTool.circle({
              strokeColor: color.strokeColor,
              strokeWeight: 6,
              strokeOpacity: 0.2,
              fillColor: color.fillColor,
              fillOpacity: 0.4,
              strokeStyle: 'solid'
            })
            self.mouseTool.on('draw', function () {
              self.mouseTool.close()
            })
            break
          case 2:
            /** 画矩形 */
            self.rectangle = self.mouseTool.rectangle({
              strokeColor: color.strokeColor,
              strokeOpacity: 0.5,
              strokeWeight: 6,
              fillColor: color.fillColor,
              fillOpacity: 0.5,
              strokeStyle: 'dashed'
            })
            self.mouseTool.on('draw', function () {
              self.mouseTool.close()
            })
            break
          case 3:
            /** 多边形 */
            self.polygon = self.mouseTool.polygon({
              strokeColor: color.strokeColor,
              strokeWeight: 6,
              strokeOpacity: 0.2,
              fillColor: color.fillColor,
              fillOpacity: 0.4,
              strokeStyle: 'solid'
            })
            self.mouseTool.on('draw', function () {
              self.mouseTool.close()
            })
            break

          default:
            break
        }
      })
    },
    /** 编辑 */
    mapEdit () {
      this.clear()
      switch (this.form.areaType) {
        case 1:
          /** 画圆 */
          break
        case 2:
          /** 画矩形 */
          this.editRectangle()
          break

        case 3:
          /** 多边形 */
          this.editPolyline()
          break
        default:
          break
      }
    },
    /** 处理经纬度数据 */
    getPath (arr) {
      let newArr = []
      switch (this.form.areaType) {
        case 1:
          /** 画圆 */
          for (let i of arr) {
            let v = `${i.lng},${i.lat}`
            newArr.push(v)
          }
          break
        case 2:
          /** 画矩形 */
          arr.forEach((item) => {
            delete item.P
            delete item.Q
          })
          this.form.area = JSON.stringify(arr)
          break
        case 3:
          /** */
          for (const v of arr) {
            let obj = {
              dLongitude: v.lng,
              dLatitude: v.lat
            }
            newArr.push(obj)
          }
          break
        default:
          break
      }
    },
    /** 画矩形 */
    editRectangle () {
      let self = this
      let color = self.editorColor[self.form.color]
      let bounds = {}
      let arr = JSON.parse(this.itemId.area)
      self.form.area = this.itemId.area
      let longitudeL = arr.sort(compareAscSort('lng'))[3].lng
      let latitudeL = arr.sort(compareAscSort('lat'))[3].lat
      let longitudeS = arr.sort(compareAscSort('lng'))[0].lng
      let latitudeS = arr.sort(compareAscSort('lat'))[0].lat
      let southWest = new AMap.LngLat(longitudeS, latitudeS)
      let northEast = new AMap.LngLat(longitudeL, latitudeL)
      bounds = new AMap.Bounds(southWest, northEast)
      let rectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: color.strokeColor,
        strokeOpacity: 0.5,
        strokeWeight: 6,
        fillColor: color.fillColor,
        fillOpacity: 0.5,
        strokeStyle: 'dashed'
      })
      rectangle.setMap(this.map)
      self.rectangle = rectangle
      this.map.setFitView([rectangle])

      this.rectangleEditor = new AMap.RectangleEditor(this.map, rectangle)
      this.rectangleEditor.open()
      this.rectangleEditor.on('adjust', function (e) {
        self.getPath(self.rectangle.getPath())
      })
    },

    /** 编辑路径 */
    editPolyline () { },

    /** 点击清除 */
    clear () {
      this.map.clearMap()
      if (this.form.area) this.form.area = ''
    }
  },
  created () { },
  mounted () {
    this.loadmap()
    this.getCompanyList()
  }
}
</script>

<style lang="scss" scoped>
.formContent {
  width: calc(100% - 32px);
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  @include themify() {
    background: themed('n1');
    padding: 16px;
    .top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-button-group {
        & > .el-button + .el-button {
          margin-left: 10px;
        }
      }
    }
    .left {
      width: calc(40% - 20px - 20px);
      .add-enclousre-form {
        padding: 1.5vh 1vw;
      }
    }
    .right {
      width: 60%;
      height: 45vh;
      padding: 0 20px 20px;
      // background: #fff;
      #container {
        width: 100%;
        height: calc(45vh - 38px);
      }
    }
  }
}
.dialog-btn {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
