<template>
  <div class="enclosure-defin" ref="enclosureDefin">
    <div class="left">
      <div ref="form" class="search-form">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item>
            <el-input
              v-model="form.keyWord"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="search"
              style="height: 32px"
            >
              <i class="iconfont iconicon_search1"></i>
              搜索
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="add"
              v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="table_cont">
        <el-table :data="tableData" stripe :height="tableHeight">
          <el-table-column
            label="序号"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            label="区域类型"
            :formatter="formatNull"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">{{
              typeObj[Number(scope.row.type)]
            }}</template>
          </el-table-column>
          <el-table-column
            label="报警类型"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">{{
              alarmTypeObj[Number(scope.row.alarmType)]
            }}</template>
          </el-table-column>
          <el-table-column
            prop="content"
            width="100"
            label="下发内容"
          ></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                @click="view(scope.row)"
                type="text"
                size="small"
                sort="primary"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="edit(scope.row)"
                v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
                sort="primary"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="del(scope.row)"
                v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
                sort="danger"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" ref="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="form.pageSize"
            :current-page="form.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加位置  -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      width="75%"
      :visible.sync="dialogTableVisible"
      v-if="dialogTableVisible"
    >
      <add-enclousre
        :itemId="itemId"
        :typeList="typeList"
        :alarmTypeList="alarmTypeList"
        :colorList="colorList"
        @closedia="closedia"
      />
    </el-dialog>
    <div class="right">
      <view-map ref="viewMap" />
    </div>
  </div>
</template>

<script>
import { formatDict } from '@/common/utils/index'
import { getLocationList, delLocation } from '@/api/lib/gps-api'
import { queryDictsByCodes } from '@/api/lib/api'
import pagination from '@/components/pagination/pagination.vue'
import addEnclousre from './addEnclousre/addEnclousre.vue'
import viewMap from './viewMap/viewMap.vue'

export default {
  components: {
    'add-enclousre': addEnclousre,
    viewMap,
    pagination
  },
  data() {
    return {
      tableHeight: 0,
      form: {
        currentPage: 1,
        keyWord: null,
        pageSize: 10
      },
      exportForm: {}, // 导出需要的数据
      total: 20,
      itemId: null,
      Visible: true,
      dialogTableVisible: false,
      tableData: [],
      typeList: [],
      alarmTypeList: [],
      colorList: [],
      typeObj: {},
      alarmTypeObj: {},
      colorObj: {},
      viewPosition: null
    }
  },

  methods: {
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.enclosureDefin.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16
    },
    /** 数据为空默认显示- */
    formatNull(row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },

    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getLocationListFn()
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.getLocationListFn()
    },

    getLocationListFn() {
      let self = this
      getLocationList(this.form).then((res) => {
        if (res.code === 1000) {
          self.exportForm = { ...self.form }
          self.tableData = res.data.list
          self.total = res.data.total
          if (!res.data.list.length && this.form.currentPage !== 1) {
            // 如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            self.form.currentPage -= 1
            self.getLocationListFn()
          }
        } else {
          self.$message.error(res.msg)
        }
      })
    },
    /** 查看 */
    view(row) {
      this.$refs.viewMap.viewMask(row)
    },
    search() {
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getLocationListFn()
    },
    add() {
      this.title = '添加区域'
      this.itemId = {}
      this.dialogTableVisible = true
    },
    edit(row) {
      this.itemId = row
      this.title = '编辑区域'
      this.dialogTableVisible = true
    },
    del(row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '此操作将删除该条数据, 是否继续?')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          delLocation({ id: row.id }).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.search()
              this.$refs.viewMap.clearMap()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },
    closedia(flag) {
      this.dialogTableVisible = false
      if (flag) this.search()
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'QYLX,QYBJLX,QYYS' }).then((res) => {
        if (res.code === 1000) {
          this.typeList = res.data.QYLX
          this.alarmTypeList = res.data.QYBJLX
          this.colorList = res.data.QYYS
          this.typeObj = formatDict(this.typeList)
          this.alarmTypeObj = formatDict(this.alarmTypeList)
          this.colorObj = formatDict(this.colorList)
        }
      })
    }
  },
  created() {
    this.search()
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.enclosure-defin {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  @include themify() {
    .left {
      margin-right: 20px;
      width: 45%;
    }
    .right {
      width: calc(55% - 20px);
      .mymap {
        width: 100%;
        height: 100%;
      }
    }
  }

  .iconfont {
    font-size: 12px;
  }
}
.theme-project-gps {
  .enclosure-defin {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
